import React from 'react';
import GraderImg from './images/grader.jpg';
import './siteGrading.css';
import ServicesNav from './ServicesNav';
import { Grid } from 'semantic-ui-react';
import PageHeader from './PageHeader';

const SiteGrading = () => {
    return (
        <div>
            <img src={GraderImg} alt="Grader" className="graderImage" style={{ display: 'block' }} />
            <PageHeader title="Site Grading" />
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <h3>Strip and stockpile topsoil when necessary.  Cut, fill, and compact clay to correct drainage issues, optimize site function, and achieve desired site grading.</h3>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <ServicesNav servicePageName={'siteGrading'} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default SiteGrading;