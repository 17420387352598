import React from 'react';
import DumpTruckSkidSteerImg from './images/dumpTruckSkidSteer.jpg';
import './dumpTruckSkidSteer.css'
import ServicesNav from './ServicesNav';
import { Grid } from 'semantic-ui-react';
import PageHeader from './PageHeader';

const DumpTruckSkidSteer = () => {
    return (
        <div>
            <img src={DumpTruckSkidSteerImg} alt="Dump Truck" className="dumpTruckSkidSteerImage" />
            <PageHeader title="Dump Truck & Skid Steer" />
            <Grid stackable columns={2}>
                <Grid.Column width={12}>
                    <h3>ECS is licensed within the state of Iowa for all your custom dump truck needs.  Whether you need a load of gravel for your driveway, or several loads of material for you project needs, we can get it done.  We also offer many different skid steer services as well, including: driveway maintenance, dirt work, light grading, concrete removal, and more</h3>
                </Grid.Column>
                <Grid.Column width={4}>
                    <ServicesNav servicePageName={'dumpTruckSkidSteer'} />
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default DumpTruckSkidSteer;