import React, { Component } from 'react';
import {Menu} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './servicesNav.css';

class ServicesNav extends Component {
    constructor(props) {
        super(props);

        this.state = { activeItem: props.servicePageName }
    }

    render () {
        const { activeItem } = this.state

    return (
        <Menu text vertical inverted>
            <Menu.Item name='OUR SERVICES' style={{ textAlign: 'center'}} />
            <Menu.Item
            as={Link}
            to='/basementExcavation'
            name='Basement Excavation'
            active={activeItem === 'basementExcavation'}
            onClick={this.handleItemClick}
            />
            <Menu.Item
            name='Civil Design'
            as={Link}
            to='/civilDesign'
            active={activeItem === 'civilDesign'}
            onClick={this.handleItemClick}
            />
            <Menu.Item
            name='Concrete & More'
            as={Link}
            to='/concrete'
            active={activeItem === 'concrete'}
            onClick={this.handleItemClick}
            />
            <Menu.Item
            name='Dump Truck & Skid Steer'
            as={Link}
            to='/dumpTruckSkidSteer'
            active={activeItem === 'dumpTruckSkidSteer'}
            onClick={this.handleItemClick}
            />
            <Menu.Item
            name='Site Grading'
            as={Link}
            to='/siteGrading'
            active={activeItem === 'siteGrading'}
            onClick={this.handleItemClick}
            />
      </Menu>
    )
    }
}

export default ServicesNav;