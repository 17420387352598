import PropTypes from 'prop-types'
import React from 'react';
import './App.css';
import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import BasementExcavation from './BasementExcavation';
import CivilDesign from './CivilDesign';
import Concrete from './Concrete';
import DumpTruckSkidSteer from './DumpTruckSkidSteer';
import SiteGrading from './SiteGrading';
import Gallery from './Gallery';
import About from './About';

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

function App() {
  return (
    <HashRouter>
      <ResponsiveContainer>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/gallery" component={Gallery} />
        {/* <Route path="/blog" component={Blog} /> */}
        <Route path="/contact" component={Contact} />
        <Route path="/basementExcavation" component={BasementExcavation} />
        <Route path="/civilDesign" component={CivilDesign} />
        <Route path="/concrete" component={Concrete} />
        <Route path="/dumpTruckSkidSteer" component={DumpTruckSkidSteer} />
        <Route path="/siteGrading" component={SiteGrading} />
      </Switch>
      </ResponsiveContainer>
    </HashRouter>
  );
}

export default App;
