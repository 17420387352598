import React from 'react';
import PageHeader from './PageHeader';

const About = () => {
    return (
        <div>
            <PageHeader title="About Us" />
            <h3>ECS believes in treating our customers as family.  Our team will go above and beyond to cater to each project’s specific needs making sure you are completely satisfied. Through open communication and exceptional service, we know you’ll find what you’re looking for with ECS. For more information or general inquiries, contact us today.</h3>
        </div>
    )
}

export default About;