const galleryProjects = [
    {
        title: "Wet Basement",
        caption: "Wet basement issues resolved. This slab settled over time, allowing the water to run towards the homes foundation. We corrected the problem by properly grading the soil, compacting it, adding gravel and compacted that as well, then added rebar to the concrete. Bring on the rain!"
        ,images: [
            { original: require('./images/gallery/drivewayProject1.jpg') },
            { original: require('./images/gallery/drivewayProject2.jpg') },
            { original: require('./images/gallery/drivewayProject3.jpg') },
            { original: require('./images/gallery/drivewayProject4.jpg') },
            { original: require('./images/gallery/drivewayProject5.jpg') },
            { original: require('./images/gallery/drivewayProject6.jpg') },
            { original: require('./images/gallery/drivewayProject7.jpg') }
        ]
    },
    {
        title: "Driveway Maintenance",
        caption: "Monday morning gravel maintenance.  Hauled in and leveled out 11.5 tons of 3/4\" roadstone."
        ,images: [
            { original: require('./images/gallery/drivewayMaint1.jpg') },
            { original: require('./images/gallery/drivewayMaint2.jpg') }
        ]
    }
]

export default galleryProjects;