import React, { Component } from 'react';
import CustomGalleryHeader from './CustomGalleryHeader';
import CustomGalleryCaption from './CustomGalleryCaption';
import ImageGallery from 'react-image-gallery';
import './customGallery.css';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';

class CustomGallery extends Component {
    render () {

        var gallery;
        if (this.props.index % 2 === 0) {
            gallery =
            <div className="wrapper">
                <div className="componentWrapper">
                    <CustomGalleryCaption caption={this.props.caption} />
                </div>
                <div className="componentWrapper">
                    <div style={{ maxWidth: '400px', display: 'inline-block' }}>
                        <ImageGallery items={this.props.images} showThumbnails={false} showBullets={true} showFullscreenButton={false} disableArrowKeys={true} showIndex={true} />
                    </div>
                </div>
            </div>
        } else {
            gallery =
            <div className="wrapper">
                <div className="componentWrapper">
                    <div style={{ maxWidth: '400px', display: 'inline-block' }}>
                        <ImageGallery items={this.props.images} showThumbnails={false} showBullets={true} showFullscreenButton={false} disableArrowKeys={true} showIndex={true} />
                    </div>
                </div>
                <div className="componentWrapper">
                    <CustomGalleryCaption caption={this.props.caption} />
                </div>
            </div>
        }

        return (
            <div style={{ marginBottom: '20px', paddingBottom: '5px', borderBottom: '1px solid rgba(255,255,255,.1)'}}>
                <CustomGalleryHeader title={this.props.title} />
                {gallery}
            </div>
        )
    }
}

export default CustomGallery;