import React from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import {Grid, Container, Header} from 'semantic-ui-react';
import PageHeader from './PageHeader';

import './contact.css'
const Contact = () => {
    return (
        <Container>
            <PageHeader title="Contact Us!" />
            <Header inverted as='h3' className="center">Please fill out the form below with any questions or comments you may have or call us with
                one of the provided numbers below.  We look forward to speaking with you soon!</Header>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <ContactForm />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <ContactInfo />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default Contact;