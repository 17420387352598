import React from 'react';
import {Icon, Header} from 'semantic-ui-react';
import './contactInfo.css';

const ContactInfo = () => {
    return (
        <table style={{ border: '1px solid white', borderRadius: '25px', minWidth: '100%', marginBottom: '5px' }}>
          <tbody>
          <tr>
            <td colSpan='2' style={{ textAlign: 'center'}}><Header inverted size='large' style={{ textDecoration: 'underline'}}>Contact Info</Header></td>
          </tr>
          <tr className='spaceUnder'>
            <td><Icon name='phone' flipped='horizontally' /></td>
            <td>
              <Header inverted size='medium'>
                <a href="tel:+1-319-505-0132">
                  319-505-0132
                </a>
                <br />
                <a href="tel:+1-319-238-9668">
                  319-238-9668
                </a>
              </Header>
            </td>
          </tr>
          <tr className='spaceUnder'>
            <td><Icon name='location arrow' /></td>
            <td><Header inverted size='medium'>1936 Plymouth Ave<br />Waterloo, IA 50702</Header></td>
          </tr>
          <tr className='spaceUnder'>
            <td><Icon name='mail' /></td>
            <td><Header id="emailHeader" inverted size='medium' >Earthworxcivilsolutions@gmail.com</Header></td>
          </tr>
          <tr className='spaceUnder'>
            <td colSpan='2' style={{ textAlign: 'center'}}>
              <Header inverted size='large' style={{ textDecoration: 'underline'}}>Visit and Like us on our social media below</Header>
            </td>
          </tr>
          <tr className='spaceUnder'>
            <td colSpan='2' style={{ textAlign: 'center'}}>
              <a href='https://www.facebook.com/earthworxiowa/' target='_blank' rel="noopener noreferrer">
                <Icon name='facebook official' inverted size='large' color='blue' />
              </a>
              <a href='https://instagram.com/earthworxiowa?igshid=1pkr7vqso2vin' target='_blank' rel="noopener noreferrer">
                <Icon name='instagram' inverted size='large' color='brown' />
              </a>
              <a href='https://www.youtube.com/channel/UCQQs4yBuhbpexQ6eqw7RErw' target='_blank' rel="noopener noreferrer">
                <Icon name='youtube play' inverted size='large' color='red' />
              </a>
            </td>
          </tr>
          </tbody>
        </table>
    )
}

export default ContactInfo;