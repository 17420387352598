import React from 'react';
import CivilDesignImg from './images/architect.jpg';
import './civilDesign.css';
import ServicesNav from './ServicesNav';
import { Grid } from 'semantic-ui-react';
import PageHeader from './PageHeader';

const CivilDesign = () => {
    return (
        <div>
        <img src={CivilDesignImg} alt="Architect" className="civilDesignImage" />
        <PageHeader title="Civil Design" />
            <Grid stackable columns={2}>
                <Grid.Column width={12}>
                    <h3>The planning, layout and implementation of site grading, excavation and paved surfaces. This also includes the interpretation of proposed plans, and establishing location and elevation of the proposed grades. We are capable of civil design pertaining to lot grading, driveways, sidewalks, and structures such as houses, garages and outbuildings.</h3>
                </Grid.Column>
                <Grid.Column width={4}>
                    <ServicesNav servicePageName={'civilDesign'} />
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default CivilDesign;