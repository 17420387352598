import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import './footer.css';

class Footer extends Component {
    render() {
        return (
            <footer style={{ position: 'absolute', bottom: '0', textAlign: 'center', width: '100%', paddingBottom: '2em' }}>
                <Header size="tiny">&copy; {new Date().getFullYear()} by Earthworx Civil Solutions, LLC.  <span className="created">Proudly designed and built by <a href="mailto:tylerlittledev@gmail.com">Tyler Little</a></span></Header>
            </footer>
        )
    }
}

export default Footer;