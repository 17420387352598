import React from 'react';
import ReactPlayer from 'react-player';
import './home.css';

const Home = () => {
    return (
        <div id="home" className='black'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <ReactPlayer className="player" url='https://www.youtube.com/watch?v=x1HHMlzD3Lc' controls={true} />
            </div>
            <h3>Earthworx Civil Solutions offers an array of construction services along with custom dump truck hauling and skid steer work throughout the Cedar Valley and surrounding areas.</h3>
            <br />
            <h3>We believe in treating our customers as family.  Our team will go above and beyond to cater to each project's specific needs making sure you are completely satisfied.  Through open communication and exceptional service, we know you'll find what you're looking for with ECS.  For more information or general inquiries, contact us today.</h3>
            
        </div>
    )
}

export default Home;