import React, { Component } from 'react';
import axios from 'axios';
import {Form, Input, Message, TextArea } from 'semantic-ui-react';
import './contactForm.css';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phone: '',
            company: '',
            message: '',
            nameError: false,
            emailError: false,
            phoneError: false,
            companyError: false,
            messageError: false,
            formError: false,
            formSubmitted: false
        }

        this.handleChange = (event) => {
            const { target: { name, value }} = event;
            this.setState({ [name]: value });
            this.setState({ formSubmitted: false });
        }

        this.handleSubmit = (e) => {
            e.preventDefault();
            let error = false;

            if (this.state.name === '') {
                this.setState({ nameError: true });
                error = true;
            } else {
                this.setState({ nameError: false });
            }

            if (this.state.email === '') {
                this.setState({ emailError: true });
                error = true;
            } else {
                this.setState({ emailError: false });
            }

            if (this.state.phone === '') {
                this.setState({ phoneError: true });
                error = true;
            } else {
                this.setState({ phoneError: false });
            }

            if (this.state.message === '') {
                this.setState({ messageError: true });
                error = true;
            } else {
                this.setState({ messageError: false });
            }

            if (error) {
                this.setState({ formError: true });
                return;
            }

            this.setState({ formError: false });
            
            const contactInfo = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                company: this.state.company,
                message: this.state.message
            };
            
            axios.post("https://70anf66aag.execute-api.us-east-2.amazonaws.com/prod/sendemail",
                contactInfo
            )
            .then((response) => {
                this.setState({ formSubmitted: true });
                this.setState({ name: '' });
                this.setState({ email: '' });
                this.setState({ phone: '' });
                this.setState({ company: '' });
                this.setState({ message: '' });
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }

    render () {

        const { name, email, phone, company, message, formSubmitted} = this.state

    return (
        <Form success={formSubmitted} onSubmit={this.handleSubmit} action="post">
            <Form.Field required error={this.state.nameError}>
                <label>Name</label>
                <Input
                name='name'
                maxLength="30"
                inverted
                value={name}
                onChange={this.handleChange}
                />
            </Form.Field>
            <Form.Field required error={this.state.emailError}>
                <label>Email</label>
                <Input
                name='email'
                maxLength="75"
                inverted
                value={email}
                onChange={this.handleChange}
                />
            </Form.Field>
            <Form.Field required error={this.state.phoneError}>
                <label>Phone</label>
                <Input
                name='phone'
                maxLength="20"
                inverted
                value={phone}
                onChange={this.handleChange}
                />
            </Form.Field>
            <Form.Field error={this.state.companyError}>
                <label>Company</label>
                <Input
                name='company'
                maxLength="100"
                inverted
                value={company}
                onChange={this.handleChange}
                />
            </Form.Field>
            <Form.Field required error={this.state.messageError}>
                <label>Message</label>
                <TextArea
                name='message'
                maxLength="1000"
                inverted="true"
                value={message}
                onChange={this.handleChange}
                />
            </Form.Field>
            <Message
                success={true}
                color="green"
                header='Form Submitted Successfully'
                content="We have received you're inquiry and will be in touch soon. Thank you!"
            />
            <Form.Button color='blue'>Submit</Form.Button>
        </Form>
    )
    }
}

export default ContactForm;