import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {
  Container,
  Dropdown,
  Menu,
  Responsive,
  Segment
} from 'semantic-ui-react'
import Footer from './Footer';
import './desktopContainer.css';

const getWidth = () => {
    const isSSR = typeof window === 'undefined'
  
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

class DesktopContainer extends Component {
    constructor(props) {
      super(props);
      
      this.state = { activeItem: 'home', isServicesOpen: false };
      this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
      this.hideFixedMenu = this.hideFixedMenu.bind(this);
      this.showFixedMenu = this.showFixedMenu.bind(this);
    }

    handleMenuItemClick = (e, { name }) => this.setState({ activeItem: name })
    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })
  
    render() {
      const { children } = this.props
      const { fixed } = this.state

      return (
        <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
            <Segment
              inverted
              textAlign='center'
              style={{ padding: '1em 0em' }}
              vertical
            >
              <Menu
                fixed={fixed ? 'top' : null}
                pointing={!fixed}
                secondary={!fixed}
                size='large'
                inverted={!fixed}
              >
                <Container className='border'>
                    <Menu.Menu position='left'>
                      <Menu.Item>
                        Earthworx Civil Solutions
                      </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        <Menu.Item as={Link} to='/' name='home' onClick={this.handleMenuItemClick}>
                            Home
                        </Menu.Item>
                        <Menu.Item as={Link} to='/about' name='about' onClick={this.handleMenuItemClick}>About Us</Menu.Item>
                        <Dropdown simple item text='Services'>
                            <Dropdown.Menu>
                            <Dropdown.Item as={Link} to='/basementExcavation' text='Basement Excavation'/>
                            <Dropdown.Item as={Link} to='/civilDesign' text='Civil Design' />
                            <Dropdown.Item as={Link} to='/concrete' text='Concrete & More' />
                            <Dropdown.Item as={Link} to='/dumpTruckSkidSteer' text='Dump Truck & Skid Steer' />
                            <Dropdown.Item as={Link} to='/siteGrading' text='Site Grading' />
                            </Dropdown.Menu>
                        </Dropdown>
                        <Menu.Item as={Link} to='/gallery'>Gallery</Menu.Item>
                        {/* <Menu.Item as='a'>Blog</Menu.Item> */}
                        <Menu.Item as={Link} to='/contact'>Contact</Menu.Item>
                        {/* <Menu.Item as='a'>Pay Bill</Menu.Item> */}
                    </Menu.Menu>
                </Container>
              </Menu>
            </Segment>
          <Container style={{ paddingBottom: '8em' }}>
          {children}
          </Container>
          <Footer />
        </Responsive>
      )
    }
  }
  
  DesktopContainer.propTypes = {
    children: PropTypes.node,
  }

export default DesktopContainer;