import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import Footer from './Footer';
import './mobileContainer.css'

const getWidth = () => {
    const isSSR = typeof window === 'undefined'
  
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

class MobileContainer extends Component {
    constructor(props) {
      super(props);

      this.state = { isServicesOpen: false }

      this.handleSidebarHide = this.handleSidebarHide.bind(this);
      this.handleToggle = this.handleToggle.bind(this);
      this.handleServicesToggle = this.handleServicesToggle.bind(this);
    }

    handleSidebarHide = () => this.setState({ sidebarOpened: false, isServicesOpen: false })
    
    handleToggle = () => this.setState({ sidebarOpened: true })

    handleServicesToggle() {
       this.setState(prevState => ({
         isServicesOpen: !prevState.isServicesOpen
       }));
    }

    render() {
      const { children } = this.props
      const { sidebarOpened } = this.state
      const isServicesOpen = this.state.isServicesOpen;

      return (
        <Responsive
          as={Sidebar.Pushable}
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <Sidebar
            as={Menu}
            animation='push'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
            fluid
          >
            <Menu.Item link onClick={this.handleSidebarHide} style={{ display: 'inline-block', width: '100%'}}><Icon name='x' size='large'/></Menu.Item>
            <Menu.Item as={Link} to='/' onClick={this.handleSidebarHide}>- Home -</Menu.Item>
            <Menu.Item as={Link} to='/about' onClick={this.handleSidebarHide}>- About Us -</Menu.Item>
            <Menu.Item link onClick={this.handleServicesToggle}>- Services -</Menu.Item>
            <Menu.Item as={Link} to='/basementExcavation' style={!isServicesOpen ? { display: 'none'} : {}} onClick={this.handleSidebarHide}>Basement Excavation</Menu.Item>
            <Menu.Item as={Link} to='/civilDesign' style={!isServicesOpen ? { display: 'none'} : {}} onClick={this.handleSidebarHide}>Civil Design</Menu.Item>
            <Menu.Item as={Link} to='/concrete' style={!isServicesOpen ? { display: 'none'} : {}} onClick={this.handleSidebarHide}>Concrete & More</Menu.Item>
            <Menu.Item as={Link} to='/dumpTruckSkidSteer' style={!isServicesOpen ? { display: 'none'} : {}} onClick={this.handleSidebarHide}>Dump Truck & Skid Steer</Menu.Item>
            <Menu.Item as={Link} to='/siteGrading' style={!isServicesOpen ? { display: 'none'} : {}} onClick={this.handleSidebarHide}>Site Grading</Menu.Item>
            <Menu.Item as={Link} to='/gallery' onClick={this.handleSidebarHide}>- Gallery -</Menu.Item>
            {/* <Menu.Item as={Link} to='/blog' onClick={this.handleSidebarHide}>Blog</Menu.Item> */}
            <Menu.Item as={Link} to='/contact' onClick={this.handleSidebarHide}>- Contact -</Menu.Item>
            {/* <Menu.Item as={Link} to='/' onClick={this.handleSidebarHide}>Pay Bill</Menu.Item> */}
          </Sidebar>
  
          <Sidebar.Pusher dimmed={sidebarOpened} style={{ margin: 'auto', minHeight: '100vh', maxWidth: '95%', paddingBottom: '8em' }}>
            <Segment
              textAlign='center'
              inverted
              style={{ padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item name='Earthworx Civil Solutions' style={{ textAlign: 'center'}} />
                  <Menu.Item position='right' href='tel:+1-319-505-0132' >
                      <Icon link name='phone' flipped='horizontally' />
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
            {children}
            <Footer />
          </Sidebar.Pusher>
        </Responsive>
      )
    }
  }
  
  MobileContainer.propTypes = {
    children: PropTypes.node,
  }

export default MobileContainer;