import React, { Component } from 'react';
import './customGalleryCaption.css';

class CustomGalleryCaption extends Component {
    render () {
        return (
            <p className="captionFont">
                {this.props.caption}
            </p>
        )
    }
}

export default CustomGalleryCaption;