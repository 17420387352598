import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';

class CustomGalleryHeader extends Component {
    render () {
        return (
            <Header inverted size='large' style={{ textAlign: 'center' }}>{this.props.title}</Header>
        )
    }
}

export default CustomGalleryHeader;