import React from 'react';
import ConcreteImg from './images/concrete.jpg';
import './concrete.css';
import ServicesNav from './ServicesNav';
import { Grid } from 'semantic-ui-react';
import PageHeader from './PageHeader';

const Concrete = () => {
    return (
        <div>
            <img src={ConcreteImg} alt="Concrete" className="concreteImage" />
            <PageHeader title="Concrete & More" />
            <Grid stackable columns={2}>
                <Grid.Column width={12}>
                    <h3>Wanting to replace that old, run down sidewalk?  Concrete driveway looking more like a gravel driveway full of potholes?  Maybe it's time for that awesome new concrete patio you've been wanting?  We are here to serve you in every step of the process from planning, to project completion of your driveways, sidewalks, foundations, and garage floors, etc.</h3>
                </Grid.Column>
                <Grid.Column width={4}>
                    <ServicesNav servicePageName={'concrete'} />
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default Concrete;