import React, { Component } from 'react';
import PageHeader from './PageHeader';

import CustomGallery from './CustomGallery';
import projects from './projects';

class Gallery extends Component {
    render () {
        let galleries = [];
        for (let i=0; i < projects.length; i++) {
            galleries.push(<CustomGallery key={i} title={projects[i].title} caption={projects[i].caption} images={projects[i].images} index={i} />)
        }
        return (
            <div>
              <PageHeader title="Gallery" />
              {galleries}
            </div>
        )
    }
}

export default Gallery;