import React from 'react';
import ExcavationImg from './images/excavation.jpg'
import './basementExcavation.css';
import ServicesNav from './ServicesNav';
import { Grid } from 'semantic-ui-react';
import PageHeader from './PageHeader';

const BasementExcavation = () => {
    return (
        <div>
            <img src={ExcavationImg} alt="Excavator" className="excavationImage" />
            <PageHeader title="Basement Excavation" />
            <Grid stackable columns={2}>
                <Grid.Column width={12}>
                    <h3>Excavate basement using survey stakes and architectural building plans.  Return to the project for back fill and compaction after footings and foundation has cured.</h3>
                </Grid.Column>
                <Grid.Column width={4}>
                    <ServicesNav servicePageName={'basementExcavation'} />
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default BasementExcavation;